import createKindeClient, { KindeOrganizations } from '@kinde-oss/kinde-auth-pkce-js'
import { App } from 'vue'

import { getTokenOrRedirect } from '@ankor-io/common/auth/client/authentication'

import { AuthenticationClientOptions, AuthenticationContext, PrincipalIdentity } from './types'

/**
 * Create a plugin for the Vue App to use the Kinde authentication client.
 *
 * - Exposes a '$principalIdentity' object in the app globals.
 * - Exposes an '$authenticationContext' object in the app globals.
 * - Exposes the KindeClient as '_kindeClient' in the app globals. (do not use this, please.)
 *
 * @param authenticationClientOptions additional options to supply to the KindeClient creation
 * @param router the router to attach Route Guards to, needs to be functioning in the app context.
 * @returns a plugin to install.
 */
export async function createAuthentication(authenticationClientOptions: AuthenticationClientOptions) {
  /**
   * init the Kinde Client immediately!
   */
  const kindeClient = await createKindeClient({
    // default logout redirect location
    logout_uri: `${window.location.origin}/`,
    redirect_uri: `${window.location.origin}/auth/callback`,
    on_redirect_callback: (_user, appState: any) => {
      // This function is called:
      //  1. After a login.
      //  2. On a direct page load/refresh (after an kinde oauth token refresh)
      //
      // See their documentation example of the SDK:
      //  https://kinde.com/docs/developer-tools/javascript-sdk/
      //    > Persisting Application State
      // it implictly only redirects when there is a appState.
      // We need to follow that.

      // redirect back based on the app state?
      if (appState?.redirectTo) {
        console.debug('app state redirect detected, using ', appState.redirectTo)

        // replace the route if in a router or use the window to redirect.
        window.location.pathname = appState.redirectTo
      }
    },
    ...authenticationClientOptions,
  })

  /**
   * Send the user to the login page.
   */
  const redirectToLogin = (options: any = {}): Promise<void> => {
    const loginOptions = {
      app_state: {
        // keep the state (path only, no host) so that the user is redirected back to where they started
        redirectTo: window.location.pathname,
      },
      org_code: authenticationClientOptions.org_code,
      ...options,
    }
    return kindeClient.login(loginOptions)
  }

  /**
   * Send the user to the logout flow.
   */
  const logout = async (): Promise<void> => {
    const token: string | undefined = await getToken()
    console.debug('Logging out user')
    console.debug(`Token: ${token}`)
    await fetch(`/api/account/logout`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    })

    // @ts-ignore - Heap is injected as a script from GTM so if available fire event
    window.heap?.resetIdentity()

    return kindeClient.logout()
  }

  /**
   * Get the user information
   * @returns the KindeUser
   */
  const getUser = (): PrincipalIdentity => {
    const user: PrincipalIdentity = kindeClient.getUser()

    // @ts-ignore - Heap is injected as a script from GTM so if available fire event
    window.heap?.identify(user.email)

    if (user?.id) {
      window.dataLayer?.push({ username: user.id })
      window.dataLayer?.push({ event: 'user-data-set' })
    }

    return user
  }

  const getUserOrganizations = async (): Promise<KindeOrganizations> => {
    return kindeClient.getUserOrganizations()
  }

  /**
   * Get the user auth token.
   * @returns the auth token.
   */
  const getToken = (): Promise<string | undefined> => {
    return getTokenOrRedirect(kindeClient, _authContext)
  }

  //
  // create a wrapper to expose
  const _authContext: AuthenticationContext = {
    redirectToLogin,
    logout,
    getUser,
    getToken,
    getUserOrganizations,
    install: (app: App): void => {
      app.config.globalProperties._kindeClient = kindeClient
      //
      app.config.globalProperties.$principalIdentity = getUser()
      app.config.globalProperties.$authenticationContext = _authContext
      app.provide('authenticationContext', _authContext)
    },
  }

  return _authContext
}
